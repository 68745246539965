<template>
  <div class="about-lime">
    <v-card color="white" width="100%" flat tile>
      <v-container class="py-10">
        <v-row>
          <!-- About Lime -->
          <v-col cols="12">
            <h1 class="heading-h3">
              About Lime24: Your Trusted Provider of Short Term Loans and Payday
              Loans Online
            </h1>
          </v-col>

          <v-col cols="12" lg="8">
            <!-- listItems1 -->
            <p
              v-for="(listItem, index) in listItems1"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <h4 class="heading-h4">
              Our Mission: Enhancing Access to Loans You Need
            </h4>

            <p class="b-body-1 mt-2">
              Our objective is to connect individuals with financial
              opportunities through our cutting-edge digital lending platform,
              making Lime24 a key player in transforming how short term and
              payday loans are accessed in South Africa. This mission compels us
              to innovate constantly, pushing the boundaries of what is possible
              in the realm of digital finance. We believe that access to
              financial services is a fundamental right, and our efforts are
              focused on making that a reality for as many people as possible.
            </p>
            <p class="b-body-1 mt-2">
              We work tirelessly to ensure that our loans are accessible,
              affordable, and fair. Our products are designed with the customer
              in mind, offering flexibility to suit different income levels and
              repayment capabilities. Whether you're facing an unexpected
              expense or need a financial bridge until your next payday, Lime24
              is here to support you with solutions that are ethical, reliable,
              and responsible.
            </p>

            <h5 style="margin-left: 25px" class="heading-h5">
              Lime24: Innovating Loans with Fintech
            </h5>
            <p style="margin-left: 25px" class="b-body-1 mt-2">
              As part of the larger Lime Credit Group, we use advanced
              algorithms and machine learning to provide a personalized lending
              experience. This approach allows us to offer flexible short term
              loans and payday loans online, catering to the diverse needs of
              our customers. Our technology-driven strategy enables us to make
              quick and accurate lending decisions, ensuring that our customers
              receive the funds they need without unnecessary delays.
            </p>
            <p style="margin-left: 25px" class="b-body-1 mt-2">
              Innovation is at the heart of everything we do at Lime24. Our
              fintech solutions are designed not only to meet the immediate
              financial needs of our customers but also to help them build a
              healthier financial future. By leveraging data analytics and
              artificial intelligence, we are able to understand our customers'
              financial behavior better and offer personalized advice and
              products that truly make a difference in their lives.
            </p>

            <h5 style="margin-left: 25px" class="heading-h5">
              Seamless Online Loans Experience with Top-notch Customer Service
            </h5>
            <p style="margin-left: 25px" class="b-body-1 mt-2">
              Our dedication to combining technology, analytics, and exceptional
              customer service has positioned Lime as a premier choice for
              online loans. We ensure a straightforward application process for
              loans online, providing quick financial support for unexpected
              life events. Our customer service team is trained to the highest
              standards, offering support and guidance throughout the loan
              application process and beyond.
            </p>
            <p style="margin-left: 25px" class="b-body-1 mt-2">
              We understand that the need for a loan often comes at a stressful
              time. That's why we've worked hard to create a user-friendly
              online platform that simplifies the application process. Our
              website is designed for ease of use, allowing customers to apply
              for a loan, upload documents, and receive approval in just a few
              clicks. But technology does not replace human touch at Lime24; our
              customer service team is always just a phone call or email away,
              ready to assist with any questions or concerns.
            </p>

            <h4 class="heading-h4">Your Partner in Financial Flexibility</h4>
            <p class="b-body-1 mt-2">
              "Unexpected expenses can arise at any moment. Whether it's for
              urgent medical bills, consolidating debts, or planning a family
              holiday, Lime understands and is here to help. Our goal is to
              provide instant access to personal loans and short term loans
              online, offering competitive rates for qualified borrowers."
            </p>
            <p class="b-body-1 mt-2">
              Financial flexibility is more than just having access to funds
              when you need them; it's about being able to navigate life's ups
              and downs with confidence. Lime24 is your partner in this journey,
              offering not just loans but also advice and support to help you
              manage your finances effectively. We believe in building long-term
              relationships with our customers, providing them with the tools
              and resources they need to achieve financial wellness.
            </p>

            <h5 style="margin-left: 25px" class="heading-h5">
              Seamless Online Loans Experience with Top-notch Customer Service
            </h5>
            <p style="margin-left: 25px" class="b-body-1 mt-2">
              Lime operates with full regulatory compliance, ensuring trust and
              reliability in our financial services. Registered with both the
              Company Intellectual Property Commission (Registration Number
              2015/239349/07) and the National Credit Regulator (Registration
              Number NCRCP8077), we are dedicated to offering secure and
              accessible payday loans and short term loans online.
            </p>
            <p style="margin-left: 25px" class="b-body-1 mt-2">
              Transparency and integrity are the cornerstones of our business.
              We adhere to all regulatory requirements, ensuring that our
              products and services are not only compliant but also fair and
              transparent. Our commitment to ethical lending practices means
              that our customers can trust us to provide financial solutions
              that are in their best interest.
            </p>

            <h4 class="heading-h4">Contact Us</h4>
            <p class="b-body-1 mt-2">
              Discover how we can assist with your financial needs. Visit us at
              C505, Bridgewater 3, Century City, Cape Town, 7441, or contact us
              at 010 442 6722 or support@lime24.co.za for more information on
              our loans online. Our team is ready to assist you with any queries
              you might have and guide you through the loan application process.
              Let Lime24 be your partner in achieving financial stability and
              success.
            </p>

            <!-- <h4 class="heading-h4 mt-4 mb-4">Registered with</h4> -->

            <!-- listItems2 -->
            <!-- <DefaultList :listItems="listItems2" /> -->

            <!-- Contact details -->
            <!-- <h4 class="heading-h4 mt-n3 mb-4">Contact details</h4> -->

            <!-- listItems3 -->
            <!-- <DefaultList :listItems="listItems3" /> -->
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DefaultList from "@/components/shared/lists/DefaultList";

export default {
  name: "LimeLoansAboutlime",

  components: { DefaultList },

  metaInfo: {
    title: "Fast and Secure Loans - Lime24 Your Trusted Partner",
    meta: [
      {
        name: "description",
        content:
          "Secure and flexible short term loans in South Africa with Lime24. Instant payday and online loans at competitive rates. Seamless application process for your financial needs. Discover more now.",
      },
    ],
  },

  data() {
    return {
      listItems1: [
        {
          text: `As a leader in the digital lending and financial services sector, 
          Lime24 is committed to offering efficient, secure, and seamless financial 
          solutions. We recognize the importance of financial stability and strive 
          to support our customers and their families in achieving a balanced 
          financial life. With a deep understanding of the unique financial 
          challenges many South Africans face, Lime24 has developed a range of 
          innovative products designed to provide immediate relief and long-term 
          support.`,
        },
        {
          text: `Our digital platform simplifies the loan application process, 
          making it accessible and straightforward for everyone. By combining 
          cutting-edge technology with our financial expertise, we've created 
          a lending experience that is not only fast but also transparent and 
          tailored to the individual needs of our clients. Our commitment to 
          financial inclusion drives us to continuously refine our services, 
          ensuring that we offer not just loans but solutions that empower our 
          customers to take control of their finances.`,
        },
      ],
      listItems2: [
        {
          text: `The Company Intellectual Property Commission – Registration
           Number 2015/239349/07`,
        },
        {
          text: `The National Credit Regulator – Registration Number NCRCP8077 `,
        },
      ],
      listItems3: [
        {
          text: `Address: C505, Bridgewater 3, Century City, Cape Town, 7441`,
        },
        {
          text: `Phone: <a href="tel:+27104426722"
                class="text-decoration-none font-weight-medium" target="_blank">
                010 442 6722</a>`,
        },
        {
          text: `Email: <a href="mailto:support@lime24.co.za"
                class="text-decoration-none font-weight-medium" target="_blank"
              >support@lime24.co.za</a>`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.about-lime {
  .h6 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
