<template>
  <div class="about-lime-page">
    <AboutLime />
  </div>
</template>

<script>
import AboutLime from "@/components/public/pages/about-lime/AboutLime.vue";

export default {
  name: "LimeLoansAboutlimepage",

  components: { AboutLime },

  metaInfo: {
    title: "Fast and Secure Loans - Lime24 Your Trusted Partner",
    meta: [
      {
        name: "description",
        content:
          "Secure and flexible short term loans in South Africa with Lime24. Instant payday and online loans at competitive rates. Seamless application process for your financial needs. Discover more now.",
      },
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
